.carousel-card {
    height: 500px; 
  }
  
  .carousel-image-container {
    height: calc(600px - 60px); 
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 20px 20px 0 20px;
  }
  
  .carousel-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .carousel-image {
    width: 100%;
    height: 100%;
  }
  